export function TableWrapper({ children }) {
  return <div className="TableWrapper">{children}</div>;
}

export function TableNestingWrapper({ colSpan, children }) {
  return (
    <td colSpan={colSpan} className="TableNestingWrapper">
      {children}
    </td>
  );
}

export function Table({ children, nested = false, fixedLayout = false }) {
  return (
    <table
      className={["Table", nested && "nested", fixedLayout && "fixedLayout"]
        .filter(Boolean)
        .join(" ")}
    >
      {children}
    </table>
  );
}

export function TableHeader({ children }) {
  return <thead>{children}</thead>;
}

export function TableBody({ children }) {
  return <tbody>{children}</tbody>;
}

export function TableRow({ children, onClick }) {
  return (
    <tr
      className={["TableRow", onClick && "clickable"].filter(Boolean).join(" ")}
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "unset" }}
    >
      {children}
    </tr>
  );
}

export function TableRowHeader({ thLabel, thValue }) {
  return (
    <th scope="row">
      <div className="th-component">
        {thLabel && <div className="label">{thLabel}</div>}
        {thValue && <div className="value">{thValue}</div>}
      </div>
    </th>
  );
}

export function TableRowItem({ label, value, title, component, className }) {
  return (
    <td>
      <div
        className={[
          "td-component",
          className && className === "styleTdComponent"
            ? "styleTdComponent"
            : "",
        ]
          .filter(Boolean)
          .join(" ")}
      >
        {label && <div className="label">{label}</div>}
        {value && (
          <div className="value" title={title}>
            {component ? component : value}
          </div>
        )}
      </div>
    </td>
  );
}
