import { useEffect } from "react";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";
import { useNavigate, useParams } from "react-router-dom";
import { dashboardRoute } from "../../routes/routes";
import { useTranslation } from "react-i18next";

export default function Redirect() {
  const { t } = useTranslation(["common", "navigation"]);
  const { action } = useParams();
  const dispatchToast = useToastsDispatch();
  const navigate = useNavigate();

  const handleSubscribed = () => {
    const toast = {
      variant: "success",
      heading: t("navigation:redirect.subscribed.heading"),
      subheading: t("navigation:redirect.subscribed.subheading"),
    };
    dispatchToast({ type: "add", ...toast });
    navigate(dashboardRoute, { replace: true });
  };

  useEffect(() => {
    switch (action) {
      case "subscribed":
        handleSubscribed();
        break;
      default:
        navigate(dashboardRoute, { replace: true });
        break;
    }
  }, [action]);

  return <></>;
}
