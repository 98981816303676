import { del, get, patch, patchFile, post, postFile } from "./requests";

const APIPath = "api/front/";

const endPoints = {
  // User
  user: APIPath + "user",
  // Active company
  companies: {
    default: APIPath + "companies",
    search: APIPath + "companies/search",
    active: APIPath + "companies/active",
  },
  // Subscription
  subscription: {
    default: APIPath + "subscription",
  },
  // Notifications
  notifications: {
    default: APIPath + "notifications",
    readAll: APIPath + "notifications/read",
  },
  // Documents
  documents: {
    default: APIPath + "documents",
  },
  // Representants
  rep: {
    default: APIPath + "representants",
  },
  // Banking
  accounts: {
    default: APIPath + "accounts",
    getURL: APIPath + "accounts/get-url",
    allStatements: APIPath + "accounts/statements",
  },
  // Clients
  clients: {
    default: APIPath + "clients",
  },
  // Invitations
  invitations: {
    default: APIPath + "invitations",
  },
  // Loan requests
  loanRequests: {
    default: APIPath + "loan-requests",
  },
  // Loan needs
  loanNeeds: {
    default: APIPath + "loan-needs",
  },
  // Loans
  loans: {
    default: APIPath + "loans",
    monthlyAmounts: APIPath + "loans" + "/monthly_amounts",
  },
  // Schedule
  schedule: {
    default: APIPath + "schedule",
  },
  // Invoice
  invoices: {
    default: APIPath + "invoices",
  },
  // Counterparties
  counterparties: {
    default: APIPath + "counterparties",
    formatted: APIPath + "counterparties/formatted",
  },
  // Enumerates
  enum: {
    default: APIPath + "enumerates",
    currencies: APIPath + "enumerates/currencies",
    countries: APIPath + "enumerates/countries",
    companyCodes: APIPath + "enumerates/identifiers",
  },
};

const api = {
  /**
   * Search for companies
   * @param {Object} searchTerm search term
   * @returns {Promise<Response>} server response with company objects
   */
  searchCompanies(searchTerm) {
    return post(endPoints.companies.search, searchTerm);
  },

  /**
   * Create company (add into companies)
   * @param {Object} company company object
   * @returns {Promise<Response>} server response
   */
  createCompany(company) {
    return post(endPoints.companies.default, company);
  },

  /**
   * Set active company
   * @param {Number} companyID
   * @returns {Promise<Response>} server response
   */
  setActiveCompany(companyID) {
    return post([endPoints.companies.default, companyID, "manage"].join("/"));
  },

  /**
   * Delete active company
   * @returns {Promise<Response>} server response
   */
  deleteActiveCompany() {
    return del(endPoints.companies.active);
  },

  /**
   * List companies
   * @returns {Promise<Response>} server response
   */
  listCompanies() {
    return get(endPoints.companies.default);
  },

  /**
   * Create company representant
   * @param {Object} rep representant object
   * @returns {Promise<Response>} server response
   */
  createRep(rep) {
    return post(endPoints.rep.default, rep);
  },

  /**
   * Update company representant
   * @param {Object} rep representant object
   * @param {Number} repID representant ID
   * @returns {Promise<Response>} server response
   */
  updateRep(rep, repID) {
    return patch(`${endPoints.rep.default}/${repID}`, rep);
  },

  /**
   * Update company representant id. card
   * @param {FormData} rep representant object with only modified ID card
   * @param {Number} repID representant ID
   * @returns {Promise<Response>} server response
   */
  updateRepIDCard(rep, repID) {
    return patchFile(`${endPoints.rep.default}/${repID}`, rep);
  },

  /**
   * List company representants
   * @returns {Promise<Response>} server response
   */
  listReps() {
    return get(endPoints.rep.default);
  },

  /**
   * Get active company information
   * @returns {Promise<Response>} server response
   */
  getActiveCompany() {
    return get(endPoints.companies.active);
  },

  /**
   * Update active company data
   * @param {Object} company company object
   * @returns {Promise<Response>} server response
   */
  updateActiveCompany(company) {
    return patch(endPoints.companies.active, company);
  },

  /**
   * Create client company
   * @param {Object} client
   * @returns {Promise<Response>} server response
   */
  createClient(client) {
    return post(endPoints.clients.default, client);
  },

  /**
   * Get list of reseller's clients
   * @returns {Promise<Response>} server response
   */
  getClients() {
    return get(endPoints.clients.default);
  },

  /**
   * Update user
   * @param {Object} user user object
   * @returns {Promise<Response>} server response
   */
  updateUser(user) {
    return patch(endPoints.user, user);
  },

  /**
   * Delete user account
   * @param {String} reason reason given by user to delete account
   * @returns {Promise<Response>} server response
   */
  deleteAccount(reason) {
    return del(`${endPoints.user}?reason=${reason}`);
  },

  /**
   * Get subscription
   * @returns {Promise<Response>} server response
   */
  getSubscription() {
    return get(endPoints.subscription.default);
  },

  /**
   * Get notifications
   * @returns {Promise<Response>} server response
   */
  getNotifications() {
    return get(endPoints.notifications.default);
  },

  /**
   * Mark all notifications as read
   * @returns {Promise<Response>} server response
   */
  readAllNotifications() {
    return post(endPoints.notifications.readAll);
  },

  /**
   * Mark notification as read
   * @param {Number} id notification ID
   * @returns {Promise<Response>} server response
   */
  readNotification(id) {
    return post([endPoints.notifications.default, id, "read"].join("/"));
  },

  /**
   * Get add bank URL
   * @returns {Promise<Response>} server response
   */
  getAddBankURL() {
    return get(endPoints.accounts.getURL);
  },

  /**
   * Get resync. bank URL
   * @param {Number} bankID
   * @returns {Promise<Response>} server response
   */
  getResyncBankURL(bankID) {
    return get(`${endPoints.accounts.getURL}?bank_id=${bankID}`);
  },

  /**
   * Get manage-account URL
   * @param {Number} accountID
   * @returns {Promise<Response>} server response
   */
  getManageAccountURL(accountID) {
    return get([endPoints.accounts.default, accountID, "get-url"].join("/"));
  },

  /**
   * Get account list
   * @returns {Promise<Response>} server response
   */
  getAccounts() {
    return get(endPoints.accounts.default);
  },

  /**
   * Change account properties
   * @param {Number} id account ID
   * @param {Object} data account props (name, opening_date, is_active)
   * @returns {Promise<Response>} server response
   */
  changeAccountProps(id, data) {
    return patch(`${endPoints.accounts.default}/${id}`, data);
  },

  /**
   * Get bank statements
   * @param {Number} id account ID
   * @returns {Promise<Response>} server response
   */
  getBankStatements(id) {
    return get(`${endPoints.accounts.default}/${id}/statements`);
  },

  /**
   * Create bank statement
   * @param {Number} accountID account ID
   * @param {FormData} data bank statement object (file, date, account)
   * @returns {Promise<Response>} server response
   */
  createBankStatement(accountID, data) {
    return postFile(
      `${endPoints.accounts.default}/${accountID}/statements`,
      data,
    );
  },

  /**
   * Delete bank statement
   * @param {Number} accountID account ID
   * @param {Number} statementID statement ID
   * @returns {Promise<Response>} server response
   */
  deleteBankStatement(accountID, statementID) {
    return del(
      `${endPoints.accounts.default}/${accountID}/statements/${statementID}`,
    );
  },

  /**
   * Get all bank-statement files
   * @returns {Promise<Response>} server response
   */
  getAllBankStatements() {
    return get(endPoints.accounts.allStatements);
  },

  /**
   * Get company documents
   * @returns {Promise<Response>} server response
   */
  getDocuments() {
    return get(endPoints.documents.default);
  },

  /**
   * Create company document
   * @param {FormData} data document object
   * @returns {Promise<Response>} server response
   */
  createDocument(data) {
    return postFile(endPoints.documents.default, data);
  },

  /**
   * Delete document
   * @param {Number} id document ID
   * @returns {Promise<Response>} server response
   */
  deleteDocument(id) {
    return del(`${endPoints.documents.default}/${id}`);
  },

  /**
   * Update client
   * @param {Number} clientID client ID
   * @param {Object} client updated client object
   * @returns {Promise<Response>} server response
   */
  updateClient(clientID, client) {
    return patch(`${endPoints.clients.default}/${clientID}`, client);
  },

  /**
   * Send invitation to join a client company
   * @param {Object} invitation object with ID of client company and manager e-mail address
   * @returns {Promise<Response>} server response
   */
  inviteClient(invitation) {
    return post(endPoints.invitations.default, invitation);
  },

  /**
   * Delete client
   * @param {Number} clientID client ID
   * @returns {Promise<Response>} server response
   */
  deleteClient(clientID) {
    return del(`${endPoints.clients.default}/${clientID}`);
  },

  /**
   * Get invitations
   * @param {Number} companyID optional company-ID filter
   * @returns
   */
  getInvitations(companyID = 0) {
    return get(
      companyID
        ? `${endPoints.invitations.default}?company=${companyID}`
        : endPoints.invitations.default,
    );
  },

  /**
   * Retrieve invitation by its key
   * @param {string} key invitation key
   * @returns {Promise<Response>} server response
   */
  getInvitation(key) {
    return get([endPoints.invitations.default, key].join("/"));
  },

  /**
   * Manage client
   * @param {Number} clientID client ID
   * @returns {Promise<Response>} server response
   */
  manageClient(clientID) {
    return post(`${endPoints.clients.default}/${clientID}/manage`);
  },

  /**
   * Assess client
   * @param {Number} clientID client ID
   * @param {Object} client client
   * @returns {Promise<Response>} server response
   */
  assessClient(clientID, client = null) {
    return post(`${endPoints.clients.default}/${clientID}/assess`, client);
  },

  /**
   * Delete manager
   * @param {Number} managerID manager ID
   * @returns {Promise<Response>} server response
   */
  deleteManager(managerID) {
    return del(`${endPoints.companies.active}/managers/${managerID}`);
  },

  /**
   * Invite user to manage active company
   * @param {Object} invitation object with manager e-mail address
   * @returns {Promise<Response>} server response
   */
  inviteUser(invitation) {
    return post(endPoints.invitations.default, invitation);
  },

  /**
   * Delete invitation
   * @param {String} invitationKey invitation key
   * @returns {Promise<Response>} server response
   */
  deleteInvitation(invitationKey) {
    return del([endPoints.invitations.default, invitationKey].join("/"));
  },

  /**
   * Create bank account
   * @param {Object} data
   * @returns {Promise<Response>} server response
   */
  createBankAccount(data) {
    return post(endPoints.accounts.default, data);
  },

  /**
   * Assess active company
   * @param {Object} metrics assessment metrics
   * @returns {Promise<Response>} server response
   */
  assessActiveCompany(metrics = null) {
    return post(`${endPoints.companies.active}/assess`, metrics);
  },

  /**
   * Get use cases
   * @param {String} need financial need
   * @returns {Promise<Response>} server response
   */
  getUsecases(need) {
    return get([endPoints.loanNeeds.default, need].join("/"));
  },

  /**
   * Register as borrower
   * @param {String} need financial need
   * @param {String} provider provider
   * @returns {Promise<Response>} server response
   */
  registerBorrower(need, provider) {
    return post(
      [endPoints.loanNeeds.default, need, "register", provider].join("/"),
    );
  },

  /**
   * Create invoice
   * @param {FormData} data invoice file + invoice type (enum.: "client"/"supplier")
   * @returns {Promise<Response>} server response
   */
  createInvoice(data) {
    return postFile(endPoints.invoices.default, data);
  },

  /**
   * Patch invoice
   * @param {Number} ID previously-created invoice ID
   * @param {Object} data invoice patch data
   * @returns {Promise<Response>} server response
   */
  patchInvoice(id, data) {
    return patch([endPoints.invoices.default, id].join("/"), data);
  },

  /**
   * Get counterparties
   * @returns {Promise<Response>} server response
   */
  getCounterparties(formatted = false) {
    const endPoint = formatted
      ? endPoints.counterparties.formatted
      : endPoints.counterparties.default;
    return get(endPoint);
  },

  /**
   * Create counterparty
   * @param {Object} counterparty counterparty data (name, country code, id)
   * @returns {Promise<Response>} server response
   */
  createCounterparty(counterparty) {
    return post(endPoints.counterparties.default, counterparty);
  },

  /**
   * Create loan request
   * @param {Object} data loan request data
   * @returns {Promise<Response>} server response
   */
  createLoanRequest(data) {
    return post(endPoints.loanRequests.default, data);
  },

  /**
   * Patch loan request
   * @param {Number} id loan-request ID
   * @param {Object} data loan-request data
   * @returns {Promise<Response>} server response
   */
  patchLoanRequest(id, data) {
    return patch([endPoints.loanRequests.default, id].join("/"), data);
  },

  /**
   * Get loan request estimate (per partner array)
   * @param {Number} id loan-request ID
   * @returns {Promise<Response>} server response
   */
  estimateLoanRequest(id) {
    return post([endPoints.loanRequests.default, id, "estimate"].join("/"));
  },

  /**
   * Request loan
   * @param {Number} id loan-request ID
   * @returns {Promise<Response>} server response
   */
  requestLoan(id) {
    return post([endPoints.loans.default, id, "request"].join("/"));
  },

  /**
   * Get loans
   * @param {String} endPoint
   * @param {String} type "active" "closed" "refused"
   * @returns {Promise<Response>} server response
   */
  getLoans(type = "", endPoint = endPoints.loans.default) {
    let url = endPoint.replace("https://front.leano.fr/api/front/", APIPath);
    if (type && !url.includes("type")) url = [url, "?type=", type].join("");
    return get(url);
  },

  /**
   * Get loan
   * @param {String} id loan id
   * @returns {Promise<Response>} server response
   */
  getLoan(id) {
    return get([endPoints.loans.default, id].join("/"));
  },

  /**
   * Get currencies
   * @returns {Promise<Response>} server response
   */
  getCurrencies() {
    return get(endPoints.enum.currencies);
  },

  /**
   * Get countries
   * @returns {Promise<Response>} server response
   */
  getCountries() {
    return get(endPoints.enum.countries);
  },

  /**
   * Get company codes
   * @returns {Promise<Response>} server response
   */
  getCompanyCodes() {
    return get(endPoints.enum.companyCodes);
  },

  /**
   * Get loan requests
   * @returns {Promise<Response>} server response
   */
  getLoanRequests() {
    return get(endPoints.loanRequests.default);
  },

  /**
   * Get loan request
   * @param {Number} loanRequestID loan-request ID
   * @returns {Promise<Response>} server response
   */
  getLoanRequest(loanRequestID) {
    return get([endPoints.loanRequests.default, loanRequestID].join("/"));
  },

  /**
   * Get invoice
   * @param {Number} invoiceID invoice ID
   * @returns {Promise<Response>} server response
   */
  getInvoice(invoiceID) {
    return get([endPoints.invoices.default, invoiceID].join("/"));
  },

  /**
   * Anticipate loan reimbursement
   * @param {string} loanID loan id
   * @param {Object} data containing date
   * @returns {Promise<Response>} server response
   */
  anticipateReimbursement(loanID, data) {
    return post(
      [endPoints.loans.default, loanID, "anticipate"].join("/"),
      data,
    );
  },

  /**
   * Get loans monthly amounts
   * @returns {Promise<Response>} server response
   */
  getMonthlyAmounts() {
    return get(endPoints.loans.monthlyAmounts);
  },

  /**
   * Get schedule
   * @param {String} provider provider
   * @returns {Promise<Response>} server response
   */
  getSchedule(provider = "") {
    let url = endPoints.schedule.default;
    if (provider) {
      url = [url, `?provider=${provider}`].join("");
    }
    return get(url);
  },
};

export default api;
