import { Trans, useTranslation } from "react-i18next";
import { Content, Header, Main } from "../commons/Template";
import api from "../../api/resources";
import { Fragment, useEffect, useState } from "react";
import Form, { FormLoader } from "../commons/Form";
import {
  AccessTime,
  ArrowForward,
  Clear,
  Download,
  FactCheck,
  List as ListIcon,
  MoreVert,
  OpenInBrowser,
  Radio,
  BarChart as BarChartIcon,
  Edit,
  Add,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import { i18n } from "../../i18n";
import { differenceInCalendarDays, formatISO } from "date-fns";
import {
  formatAmount,
  formatDate,
  formatDateTime,
  getCurrencySymbol,
} from "../../format";
import Tabs from "../commons/Tabs";
import Input from "../commons/Input";
import Button from "../commons/Button";
import { Drawer, Switch, ThemeProvider } from "@mui/material";
import { DrawerHeader } from "../commons/Drawer";
import usePartners from "./usePartners";
import ImageContainer from "../commons/ImageContainer";
import {
  Table,
  TableBody,
  TableHeader,
  TableNestingWrapper,
  TableRow,
  TableRowHeader,
  TableRowItem,
  TableWrapper,
} from "../commons/Table";
import { Pill } from "./Assessment";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Check } from "react-feather";
import * as XLSX from "xlsx";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";
import { switchTheme } from "../commons/Switch";
import { BarChart } from "@mui/x-charts";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { capitalize } from "../../utils";
import { useAuth } from "../../auth/AuthContext";
import EmptySection from "../commons/EmptySection";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes";

export default function Loans() {
  const { t } = useTranslation(["common", "financing"]);
  document.title = t("financing:loans.title");

  const [loading, setLoading] = useState(true);
  const [loanCounters, setLoanCounters] = useState(null);

  const [graphShown, setGraphShown] = useState(false);
  const [amounts, setAmounts] = useState(null);

  const [loans, setLoans] = useState(null);
  const [loanRequests, setLoanRequests] = useState(null);
  const [schedules, setSchedules] = useState(null);
  const { activeCompany, loadingActiveCompany } = useAuth();

  const handleSelectDraftTab = async () => {
    setLoading(true);
    const loanRequestsResponse = await api.getLoanRequests();
    if (loanRequestsResponse.ok) {
      const jsonLoanRequests = await loanRequestsResponse.json();
      setLoanRequests(jsonLoanRequests);
    }
    setLoading(false);
  };

  const handleSelectLoanTab = async (id) => {
    setLoading(true);
    const loansResponse = await api.getLoans(id);
    if (loansResponse.ok) {
      const jsonLoans = await loansResponse.json();
      setLoanCounters({
        pending: jsonLoans.pending,
        accepted: jsonLoans.accepted,
      });
      setLoans(jsonLoans.results);
      setMoreLoans(jsonLoans.next);
    }
    setLoading(false);
  };

  const handleSelectScheduleTab = async () => {
    setLoading(true);
    const scheduleResponse = await api.getSchedule();
    if (scheduleResponse.ok) {
      const jsonSchedule = await scheduleResponse.json();
      setSchedules(jsonSchedule);
    }
    setLoading(false);
  };

  const handleSelectLoansGraph = async () => {
    setLoading(true);
    const amountsResponse = await api.getMonthlyAmounts();
    if (amountsResponse.ok) {
      const jsonAmounts = await amountsResponse.json();

      // Set amounts only if there is at least one positive value
      if (jsonAmounts.some((amount) => amount.borrowed || amount.paid)) {
        setAmounts(jsonAmounts);
      }
      setLoading(false);
    }
  };

  const handleSelectScheduleGraph = () => {
    handleSelectScheduleTab();
  };

  const tabs = [
    {
      id: "draft",
      onSelect: handleSelectDraftTab,
      hidden: graphShown,
    },
    {
      id: "active",
      onSelect: () => handleSelectLoanTab("active"),
      hidden: graphShown,
    },
    {
      id: "closed",
      onSelect: () => handleSelectLoanTab("closed"),
      hidden: graphShown,
    },
    {
      id: "refused",
      onSelect: () => handleSelectLoanTab("refused"),
      hidden: graphShown,
    },
    {
      id: "schedules",
      title: t("common:schedule"),
      onSelect: handleSelectScheduleTab,
      hidden: graphShown,
    },
    {
      id: "loansGraph",
      onSelect: handleSelectLoansGraph,
      hidden: !graphShown,
    },
    {
      id: "scheduleGraph",
      onSelect: handleSelectScheduleGraph,
      hidden: !graphShown,
    },
  ];

  useEffect(() => {
    if (graphShown) {
      handleSelectTab("loansGraph");
    } else {
      handleSelectTab("active");
    }
  }, [graphShown]);

  const [selectedTab, setSelectedTab] = useState(
    !graphShown ? "active" : "loansGraph",
  );
  const handleSelectTab = (id) => {
    setLoading(true);
    setSelectedTab(id);
    tabs.find((tab) => tab.id === id).onSelect();
  };

  const init = async () => {
    setLoading(true);

    // 1 - Init loan counters
    const loansResponse = await api.getLoans();
    if (loansResponse.ok) {
      const jsonLoans = await loansResponse.json();
      if (
        Object.keys(jsonLoans).includes("pending") ||
        Object.keys(jsonLoans).includes("accepted")
      ) {
        setLoanCounters({
          pending: jsonLoans.pending,
          accepted: jsonLoans.accepted,
        });
      }
    }

    // 2 - Init graph data
    const amountsResponse = await api.getMonthlyAmounts();
    if (amountsResponse.ok) {
      const jsonAmounts = await amountsResponse.json();

      // Set amounts only if there is at least one positive value
      if (jsonAmounts.some((amount) => amount.borrowed || amount.paid)) {
        setAmounts(jsonAmounts);
      }
    }

    // 3 - Init the initially selected tab
    tabs.find((tab) => tab.id === selectedTab).onSelect();

    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const [moreLoans, setMoreLoans] = useState(null);
  const [refreshingLoans, setRefreshingLoans] = useState(false);
  const getMoreLoans = async () => {
    setRefreshingLoans(true);
    if (moreLoans && selectedTab !== "draft") {
      const loansResponse = await api.getLoans(selectedTab, moreLoans);
      if (loansResponse.ok) {
        const jsonLoans = await loansResponse.json();

        const moreResults = jsonLoans.results;

        if (moreResults.length > 0) {
          setLoans((prevLoans) => [...prevLoans, ...moreResults]);
        }

        setMoreLoans(jsonLoans.next);
        setRefreshingLoans(false);
      }
    }
  };

  const [selectedPartner, setSelectedPartner] = useState(null);

  useEffect(() => {
    const handleSelectedPartnerChange = async () => {
      setLoading(true);
      // works for both when a partner is selected and when it's cleared
      const response = await api.getSchedule(
        selectedPartner ? selectedPartner.value : "",
      );
      if (response.ok) {
        const jsonSchedules = await response.json();
        setSchedules(jsonSchedules);
        setLoading(false);
      }
    };

    handleSelectedPartnerChange();
  }, [selectedPartner]);

  // Graphs
  const sortByAscendingDate = (a, b) => (a.month > b.month ? 1 : -1);

  // loansGraph
  const borrowedOption = {
    label: t("financing:loans.loansGraph.radio.borrowedAmount"),
    value: "borrowed",
    color: "var(--color-leano-3)",
  };

  const paidOption = {
    label: t("financing:loans.loansGraph.radio.paidAmount"),
    value: "paid",
    color: "var(--color-leano-2)",
  };

  // scheduleGraph
  const capitalOption = {
    label: t("financing:loans.scheduleGraph.radio.capital"),
    value: "capital",
    color: "var(--color-leano-3)",
  };

  const interestOption = {
    label: t("financing:loans.scheduleGraph.radio.interest"),
    value: "interest",
    color: "var(--color-leano-2)",
  };

  return (
    <Main themeSwitch>
      <Header
        heading={t("financing:loans.title")}
        subheading={t("financing:loans.subheading")}
      />
      {loading || loadingActiveCompany ? (
        <FormLoader />
      ) : (
        <Content style={{ display: "flex", flexDirection: "column" }}>
          <div className="LoanHeader">
            {loanCounters && <LoanCounters loanCounters={loanCounters} />}
            <GraphSwitch value={graphShown} onSwitch={setGraphShown} />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <LoanTabs
              tabs={tabs.filter((tab) => !tab.hidden)}
              selectedTab={selectedTab}
              onSelect={handleSelectTab}
            />

            {!graphShown ? (
              <Fragment>
                {loans &&
                  tabs
                    .filter((tab) =>
                      ["active", "closed", "refused"].some(
                        (loanTab) => tab.id === loanTab,
                      ),
                    )
                    .map((tab, index) => (
                      <div
                        key={index}
                        style={{
                          display: tab.id === selectedTab ? "block" : "none",
                        }}
                      >
                        <LoanList
                          tabID={tab.id}
                          tabLoanList={loans}
                          onRefresh={getMoreLoans}
                          noMoreRefresh={!moreLoans}
                          refreshing={refreshingLoans}
                          loading={loading}
                        />
                      </div>
                    ))}
                <div
                  style={{
                    display: selectedTab === "draft" ? "block" : "none",
                  }}
                >
                  {loanRequests && (
                    <LoanRequestList tabID="draft" tabLoanList={loanRequests} />
                  )}
                </div>
                <div
                  style={{
                    display: selectedTab === "schedules" ? "block" : "none",
                  }}
                >
                  {schedules && (
                    <ScheduleList
                      tabID="schedules"
                      schedules={schedules}
                      selectedPartner={selectedPartner}
                      onSelectedPartnerChange={setSelectedPartner}
                      activeCompanyCurrency={activeCompany.currency}
                    />
                  )}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div
                  style={{
                    display: selectedTab === "loansGraph" ? "block" : "none",
                  }}
                >
                  <Graph
                    heading={t("financing:loans.loansGraph.header.heading")}
                    subheading={t(
                      "financing:loans.loansGraph.header.subheading",
                    )}
                    amounts={amounts}
                    onSort={sortByAscendingDate}
                    currency={activeCompany.currency}
                    options={[borrowedOption, paidOption]}
                    initialOption={borrowedOption}
                  />
                </div>
                <div
                  style={{
                    display: selectedTab === "scheduleGraph" ? "block" : "none",
                  }}
                >
                  <Graph
                    heading={t("financing:loans.scheduleGraph.header.heading")}
                    subheading={t(
                      "financing:loans.scheduleGraph.header.subheading",
                    )}
                    amounts={schedules.map((schedule) => ({
                      month: schedule.month,
                      capital: schedule.capital,
                      interest: schedule.interest,
                    }))}
                    onSort={sortByAscendingDate}
                    currency={activeCompany.currency}
                    options={[capitalOption, interestOption]}
                    initialOption={capitalOption}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </Content>
      )}
    </Main>
  );
}

function useActiveStatus() {
  return ["accepted", "ongoing", "overdue", "pending"];
}

function useFilterOptions() {
  const [filterOptions, setFilterOptions] = useState([]);
  const handleFilterOptionsChange = (id, newFilterFn) =>
    setFilterOptions((prevFilterOptions) => {
      // if new filter function, add it
      if (newFilterFn) {
        return [
          ...prevFilterOptions.filter((filter) => filter.id !== id),
          {
            id: id,
            filterFn: newFilterFn,
          },
        ];
      }
      // else, remove it
      return [...prevFilterOptions.filter((filter) => filter.id !== id)];
    });

  const filterByOptions =
    filterOptions.length > 0
      ? filterOptions
          .map((filterOption) => filterOption.filterFn)
          .reduce((x, y) => (z) => x(z) && y(z))
      : () => true;

  return {
    handleFilterOptionsChange,
    filterByOptions,
  };
}

function EmptyList({ children }) {
  const { t } = useTranslation(["common", "financing"]);
  return (
    <div
      style={{
        padding: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children ? (
        children
      ) : (
        <EmptySection text={t("common:noData")} icon={<ListIcon />} />
      )}
    </div>
  );
}

function List({ children }) {
  return <div className="List">{children}</div>;
}

function ListControls({ children }) {
  return <div className="ListControls">{children}</div>;
}

function LoanList({
  tabID,
  tabLoanList,
  onRefresh,
  noMoreRefresh,
  refreshing,
}) {
  const { handleFilterOptionsChange, filterByOptions } = useFilterOptions();

  // Array of unique status values from current subset of loans
  const availableStatus = Array.from(
    new Set(tabLoanList.map((loan) => loan.status)),
  );

  // Array of unique partner values from current subset of loans
  const availablePartners = Array.from(
    new Set(tabLoanList.map((loan) => loan.provider)),
  );

  // Drawer
  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = () => setDrawer((drawer) => !drawer);

  const [selectedLoan, setSelectedLoan] = useState(null);
  const handleSelectLoan = (id) => {
    setSelectedLoan(tabLoanList.find((loan) => loan.id == id));
    toggleDrawer();
  };

  const { t } = useTranslation(["common", "financing"]);
  const getCommonT = (word) => t(`common:${word}`);
  const formatAmountFn = (amount, currency) =>
    amount ? formatAmount(amount, lang, currency) : "-";
  const lang = i18n.resolvedLanguage;

  const statusPillColors = {
    accepted: "green",
    ongoing: "green",
    overdue: "red",
    pending: "yellow",
  };

  const sortByDescendingIntID = (a, b) => (a.int_id < b.int_id ? 1 : -1);
  const computeRows = (tabLoanList) =>
    tabLoanList.sort(sortByDescendingIntID).map((loan) => {
      const currency = loan.currency;
      return {
        thValue: loan.int_id,
        onClick: () => handleSelectLoan(loan.id),
        items: [
          {
            label: getCommonT("creationDate"),
            value: formatDate(loan.creation_datetime, lang),
            rawValue: formatDate(loan.creation_datetime, lang),
          },
          {
            label: getCommonT("amount"),
            value: formatAmountFn(loan.amount, currency),
            rawValue: loan.amount,
          },
          loan.months
            ? {
                label: getCommonT("duration"),
                value: `${loan.months} ${
                  loan.months > 1 ? getCommonT("months") : getCommonT("month")
                }`,
                rawValue: `${loan.months} ${
                  loan.months > 1 ? getCommonT("months") : getCommonT("month")
                }`,
              }
            : {
                label: getCommonT("duration"),
                value: `${loan.days} ${
                  loan.days > 1 ? getCommonT("days") : getCommonT("day")
                }`,
                rawValue: `${loan.days} ${
                  loan.days > 1 ? getCommonT("days") : getCommonT("day")
                }`,
              },
          {
            label: getCommonT("funder"),
            value: loan.provider,
            rawValue: loan.provider,
          },
          {
            label: getCommonT("cost"),
            value: formatAmountFn(loan.cost, currency),
            rawValue: loan.cost,
          },
          tabID === "active"
            ? {
                label: getCommonT("status"),
                value: loan.status,
                rawValue: t(`financing:loans.status.${loan.status}`),
                component: (
                  <Pill
                    text={t(`financing:loans.status.${loan.status}`)}
                    color={statusPillColors[loan.status]}
                  />
                ),
              }
            : {},
          {
            value: <Button className="rowButton" icon={<MoreVert />} />,
          },
        ],
      };
    });

  const handleExportXLSX = (rows) => {
    const headers = rows[0].items.map((item) => item.label);
    const worksheetData = rows.map((row) =>
      row.items.map((item) => item.rawValue),
    );
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...worksheetData]);
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, tabID);
    XLSX.writeFile(newWorkbook, `${tabID}.xlsx`);
  };

  const filteredRows = computeRows(tabLoanList.filter(filterByOptions));

  if (tabLoanList.length === 0) return <EmptyList />;
  return (
    <List>
      {tabLoanList.length > 0 && (
        <ListControls>
          <ListFilters>
            <div style={{ display: "none" }}>
              <CreationDateTimeFilter
                tabLoanList={tabLoanList}
                onChange={(filterFn) =>
                  handleFilterOptionsChange("creation_datetime", filterFn)
                }
              />
            </div>
            <CreationDateTimeFilter2
              onChange={(filterFn) =>
                handleFilterOptionsChange("creation_datetime", filterFn)
              }
            />
            <PartnerFilter
              availablePartners={availablePartners}
              onChange={(filterFn) =>
                handleFilterOptionsChange("partner", filterFn)
              }
            />
            {tabID === "active" && (
              <StatusFilter
                availableStatus={availableStatus}
                onChange={(filterFn) =>
                  handleFilterOptionsChange("status", filterFn)
                }
              />
            )}
          </ListFilters>

          <Button
            className="SecondaryButton"
            onClick={() => handleExportXLSX(filteredRows)}
            variant={"secondary_b"}
            text={t("financing:loans.table.export")}
            icon={<Download />}
          />
        </ListControls>
      )}

      <TableWrapper>
        <Table fixedLayout>
          <TableBody>
            {filteredRows.map((row, index) => (
              <Fragment key={index}>
                <TableRow onClick={row.onClick}>
                  <TableRowHeader
                    thLabel={t("common:application")}
                    thValue={row.thValue}
                  />
                  {row.items.map((item, itemIndex) =>
                    Object.keys(item).length > 0 ? (
                      <TableRowItem
                        key={itemIndex}
                        {...item}
                        title={item.value}
                      />
                    ) : (
                      <></>
                    ),
                  )}
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
        <Button
          style={{
            marginBottom: "1rem",
            alignSelf: "center",
            display: noMoreRefresh ? "none" : "flex",
          }}
          className="SecondaryButton"
          onClick={onRefresh}
          variant={"secondary_b"}
          icon={!refreshing ? <ExpandMore /> : "..."}
          disabled={refreshing}
        />
      </TableWrapper>

      <LoanDetailsDrawer
        drawer={drawer}
        toggleDrawer={toggleDrawer}
        selectedLoan={selectedLoan}
      />
    </List>
  );
}

function LoanDetailsDrawer({ drawer, toggleDrawer, selectedLoan }) {
  return (
    <Drawer
      anchor={"right"}
      open={drawer}
      onClose={toggleDrawer}
      className="PartnersDrawer"
    >
      <DrawerHeader onClose={toggleDrawer} />

      {selectedLoan && (
        <LoanDetails>
          <LoanDetailsHeader selectedLoan={selectedLoan} />
          <StatusChapter selectedLoan={selectedLoan} />
          <BankingChapter
            selectedLoan={selectedLoan}
            onModalOpen={() => toggleDrawer()}
          />
          <DocumentChapter selectedLoan={selectedLoan} />
        </LoanDetails>
      )}
    </Drawer>
  );
}

function LoanRequestList({ tabLoanList }) {
  // Array of unique usecase values from current subset of loan requests
  const availableUsecases = Array.from(
    new Set(tabLoanList.map((loanRequest) => loanRequest.use_case)),
  );

  // Array of unique need values from current subset of loan requests
  const availableNeeds = Array.from(
    new Set(tabLoanList.map((loanRequest) => loanRequest.need)),
  );

  const navigate = useNavigate();
  const { handleFilterOptionsChange, filterByOptions } = useFilterOptions();

  const { t } = useTranslation(["common", "financing"]);
  const getCommonT = (word) => t(`common:${word}`);
  const formatAmountFn = (amount, currency) =>
    amount ? formatAmount(amount, lang, currency) : "-";
  const lang = i18n.resolvedLanguage;

  const sortByAscendingID = (a, b) => (a.id > b.id ? 1 : -1);
  const computeRows = (tabLoanList) =>
    tabLoanList.sort(sortByAscendingID).map((loanRequest) => {
      const currency = loanRequest.currency;
      return {
        thValue: loanRequest.id,
        onClick: () => {
          const target = routes.Financing.request;
          const state = {
            need: loanRequest.need,
            usecase: loanRequest.use_case,
            initID: loanRequest.id,
            startWithEdit: false,
          };
          navigate(target, { state: state });
        },
        items: [
          {
            label: getCommonT("need"),
            value: t(
              `financing:assessment.results.keys.${loanRequest.need}.name`,
            ),
          },
          {
            label: getCommonT("usecase"),
            value: t(
              `financing:unlock.usecases.ids.${loanRequest.use_case}.title`,
            ),
          },
          {
            label: getCommonT("starting_date"),
            value: formatDate(loanRequest.starting_date, lang),
          },
          {
            label: getCommonT("amount"),
            value: formatAmountFn(loanRequest.amount, currency),
          },
          {
            value: (
              <Button
                className="rowButton"
                title={t("common:modify")}
                icon={<Edit />}
              />
            ),
          },
        ],
      };
    });

  const filteredRows = computeRows(tabLoanList.filter(filterByOptions));

  if (tabLoanList.length === 0)
    return (
      <EmptyList>
        <LoanRequestCreationButton />
      </EmptyList>
    );

  return (
    <List>
      <ListControls>
        <ListFilters>
          <NeedFilter
            availableNeeds={availableNeeds}
            onChange={(filterFn) => handleFilterOptionsChange("need", filterFn)}
          />
          <UsecaseFilter
            availableUsecases={availableUsecases}
            onChange={(filterFn) =>
              handleFilterOptionsChange("usecase", filterFn)
            }
          />
          <div style={{ display: "none" }}>
            <StartingDateFilter
              tabLoanList={tabLoanList}
              onChange={(filterFn) =>
                handleFilterOptionsChange("starting_date", filterFn)
              }
            />
          </div>
          <StartingDateFilter2
            onChange={(filterFn) =>
              handleFilterOptionsChange("starting_date", filterFn)
            }
          />
        </ListFilters>
        <LoanRequestCreationButton />
      </ListControls>
      <TableWrapper>
        <Table fixedLayout>
          <TableBody>
            {filteredRows.map((row, index) => (
              <TableRow key={index} onClick={row.onClick}>
                <TableRowHeader
                  thLabel={t("common:draft")}
                  thValue={row.thValue}
                />
                {row.items.map((item, itemIndex) => (
                  <TableRowItem key={itemIndex} {...item} title={item.value} />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </List>
  );
}

function ScheduleList({
  schedules,
  onSelectedPartnerChange,
  selectedPartner,
  activeCompanyCurrency,
}) {
  // Array of unique partner values from schedules details
  let availablePartners = [];
  schedules.forEach((schedule) => {
    schedule.detail.forEach((detail) => {
      availablePartners.push(detail.provider);
    });
  });
  availablePartners = Array.from(new Set(availablePartners));

  const [selectedRowIndex, setSelectedRowIndex] = useState("");

  const handleSelectRow = (newRow) => {
    setSelectedRowIndex((prevRow) => (prevRow === newRow ? "" : newRow));
  };

  const { t } = useTranslation(["common", "financing"]);
  const getCommonT = (word) => t(`common:${word}`);
  const formatAmountFn = (amount, currency) =>
    amount ? formatAmount(amount, lang, currency) : "-";
  const lang = i18n.resolvedLanguage;

  const sortByDescendingDate = (a, b) => (a.month < b.month ? 1 : -1);
  const convertScheduleIntoRow = (schedule, scheduleIndex) => {
    const row = {
      thValue: capitalize(formatDate(schedule.month, lang, "year-month")),
      onClick: () => handleSelectRow(scheduleIndex),
      items: [
        {
          label: t("common:amount"),
          value: formatAmountFn(schedule.total, activeCompanyCurrency),
        },
        {
          label: getCommonT("capital"),
          value: formatAmountFn(schedule.capital, activeCompanyCurrency),
        },
        {
          label: getCommonT("interest"),
          value: formatAmountFn(schedule.interest, activeCompanyCurrency),
        },
        {
          value: (
            <Button
              className="rowButton"
              icon={
                selectedRowIndex === scheduleIndex ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              }
            />
          ),
        },
      ],
      nestedTable: {
        th: [
          t("common:date"),
          t("common:amount"),
          t("common:capital"),
          t("common:interest"),
          t("common:funder"),
        ],
        tr: schedule.detail.map((loan) => ({
          onClick: () => handleSelectLoan(loan.id),
          td: [
            formatDate(loan.date, lang),
            formatAmountFn(loan.amount, activeCompanyCurrency),
            formatAmountFn(loan.capital, activeCompanyCurrency),
            formatAmountFn(loan.interest, activeCompanyCurrency),
            loan.provider,
          ],
        })),
      },
    };
    return row;
  };

  const rows = schedules.sort(sortByDescendingDate).map(convertScheduleIntoRow);

  // Drawer
  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = () => setDrawer((drawer) => !drawer);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const handleSelectLoan = async (id) => {
    const response = await api.getLoan(id);
    if (response.ok) {
      const jsonLoan = await response.json();
      setSelectedLoan(jsonLoan);
    }
  };

  useEffect(() => {
    if (selectedLoan) {
      toggleDrawer();
    }
  }, [selectedLoan]);

  if (schedules.length === 0) return <EmptyList />;
  return (
    <List>
      {schedules.length > 0 && (
        <ListControls>
          <ListFilters>
            <PartnerFilterWithAPIQuery
              availablePartners={availablePartners}
              selectedPartner={selectedPartner}
              onSelectedPartnerChange={onSelectedPartnerChange}
            />
          </ListFilters>
        </ListControls>
      )}
      <TableWrapper>
        <Table fixedLayout>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <Fragment key={rowIndex}>
                <TableRow onClick={row.onClick}>
                  <TableRowHeader thValue={row.thValue} />
                  {row.items.map((item, itemIndex) =>
                    Object.keys(item).length > 0 ? (
                      <TableRowItem
                        key={itemIndex}
                        {...item}
                        title={item.value}
                      />
                    ) : (
                      <></>
                    ),
                  )}
                </TableRow>

                {selectedRowIndex === rowIndex && (
                  <TableRow>
                    {/* + 1 is for the first column that is computed later */}
                    <TableNestingWrapper colSpan={row.items.length + 1}>
                      <Table nested fixedLayout>
                        <TableHeader>
                          <TableRow>
                            {row.nestedTable.th.map((th, thIndex) => (
                              <th key={thIndex}>{th}</th>
                            ))}
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {row.nestedTable.tr.map((tr, trIndex) => (
                            <TableRow key={trIndex} onClick={tr.onClick}>
                              {tr.td.map((td, tdIndex) => (
                                <td key={tdIndex}>{td}</td>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableNestingWrapper>
                  </TableRow>
                )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
      <LoanDetailsDrawer
        drawer={drawer}
        toggleDrawer={toggleDrawer}
        selectedLoan={selectedLoan}
      />
    </List>
  );
}

function PartnerFilterWithAPIQuery({
  availablePartners,
  onSelectedPartnerChange,
  selectedPartner,
}) {
  const { t } = useTranslation(["common"]);

  const options = availablePartners.map((partner) => ({
    label: partner,
    value: partner,
  }));

  const input = {
    type: "select",
    placeholder: t("common:funder"),
    value: selectedPartner,
    onChange: onSelectedPartnerChange,
    isClearable: true,
    options: options,
  };
  return <Input className={"ListFilter"} {...input} />;
}

function LoanRequestCreationButton() {
  const { t } = useTranslation(["common", "financing"]);
  const navigate = useNavigate();

  return (
    <Button
      className="SecondaryButton"
      onClick={() => navigate(routes.Financing.assess)}
      icon={<Add />}
      text={t("financing:loans.table.createNewRequest")}
      variant="secondary_b"
      iconLeft
    />
  );
}

function GraphSwitch({ value, onSwitch }) {
  const { t } = useTranslation(["common", "financing"]);
  const handleSwitchChange = ({ target: { checked } }) => onSwitch(checked);

  return (
    <div className="GraphSwitch">
      <span className="GraphSwitch-label">{t("common:table")}</span>

      <ThemeProvider theme={switchTheme}>
        <Switch
          size="small"
          checked={value}
          onChange={(newValue) => handleSwitchChange(newValue)}
          inputProps={{ "aria-label": "controlled" }}
        />
      </ThemeProvider>
      <span className="GraphSwitch-label">{t("common:graph")}</span>
    </div>
  );
}

function Graph({
  heading,
  subheading,
  amounts = [],
  options,
  initialOption,
  onSort,
  currency = "EUR",
}) {
  const { t } = useTranslation(["common", "financing"]);
  const lang = i18n.resolvedLanguage;

  const [selectedOption, setSelectedOption] = useState(initialOption);

  const graphControlInput = {
    type: "radio",
    value: selectedOption,
    onChange: setSelectedOption,
    options: options,
  };

  if (!amounts)
    return (
      <div
        style={{
          padding: "1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <EmptySection text={t("common:noData")} icon={<BarChartIcon />} />
      </div>
    );
  return (
    <div className="Graph">
      <div className="Graph-header">
        <p className="Graph-header-heading">{heading}</p>
        <p className="Graph-header-subheading">{subheading}</p>
      </div>
      <div className="Graph-controls">
        <Input {...graphControlInput} rowRadio />
      </div>
      <BarChart
        series={[
          {
            data: amounts
              .sort(onSort)
              .map((amount) => amount[selectedOption.value]),
            valueFormatter: (value) =>
              formatAmount(value, i18n.resolvedLanguage, currency),
            color: options.find(
              (option) => option.value === selectedOption.value,
            ).color,
          },
        ]}
        height={290}
        xAxis={[
          {
            data: amounts
              .sort(onSort)
              .map((amount) =>
                capitalize(
                  formatDate(
                    amount.month,
                    i18n.resolvedLanguage,
                    "year-shortMonth",
                  ),
                ),
              ),
            scaleType: "band",
          },
        ]}
        margin={{ top: 10, bottom: 20 }}
        borderRadius={10}
        yAxis={[
          {
            valueFormatter: (value) =>
              formatAmount(value, lang, currency, true),
          },
        ]}
        grid={{ vertical: true, horizontal: true }}
      />
    </div>
  );
}

function LoanDetails({ children }) {
  return <div className="LoanDetails">{children}</div>;
}

function LoanDetailsHeader({ selectedLoan }) {
  const { t } = useTranslation(["common", "financing"]);
  const partners = usePartners();

  return (
    <div className="LoanDetails-header">
      <ImageContainer
        className="LoanDetails-header-logo"
        src={
          partners.find((partner) => partner.id === selectedLoan.provider).logo
        }
      />
      <div className="LoanDetails-header-text">
        <p className="LoanDetails-header-text-title">
          {t("financing:loans.details.header")}
        </p>
        <p className="LoanDetails-header-text-subtitle">
          {selectedLoan.ext_id}
        </p>
      </div>
    </div>
  );
}

function LoanDetailsChapter({ icon, title, children, className = "" }) {
  return (
    <div
      className={["LoanDetails-chapter", className].filter(Boolean).join(" ")}
    >
      <p className="LoanDetails-chapter-title">
        {icon} <span>{title}</span>
      </p>
      <div className="LoanDetails-chapter-content">{children}</div>
    </div>
  );
}

function StatusChapter({ selectedLoan }) {
  const { t } = useTranslation(["common", "financing"]);
  const lang = i18n.resolvedLanguage;
  const active = useActiveStatus();

  const defaultItem = {
    className: "unknown",
    icon: <Radio />,
    text: "",
    datetime: "",
  };

  // Item 1: dispatch
  const sentDispatch = {
    className: "success",
    icon: <Check />,
    text: t("financing:loans.details.statusChapter.dispatch.sent"),
    datetime: formatDateTime(selectedLoan.creation_datetime, lang),
  };

  // Item 2: response
  const pendingResponse = {
    className: "pending",
    icon: <AccessTime />,
    text: t("financing:loans.details.statusChapter.response.pending"),
  };

  const refusedResponse = {
    className: "error",
    icon: <Clear />,
    text: t("financing:loans.details.statusChapter.response.refused"),
  };

  const acceptedResponse = {
    className: "success",
    icon: <Check />,
    text: t("financing:loans.details.statusChapter.response.accepted"),
  };

  // Item 3: reimbursement
  const pendingReimbursement = {
    className: "pending",
    icon: <AccessTime />,
    text: t("financing:loans.details.statusChapter.reimbursement.pending"),
    datetime: formatDate(selectedLoan.ending_date, lang),
  };

  const reimbursementOK = {
    className: "success",
    icon: <Check />,
    text: t("financing:loans.details.statusChapter.reimbursement.done"),
    datetime: formatDate(selectedLoan.reimbursement_date, lang),
  };

  let items = [
    sentDispatch,
    {
      ...(["pending", "retry", "unknown"].includes(selectedLoan.status)
        ? pendingResponse
        : selectedLoan.status === "refused"
          ? refusedResponse
          : acceptedResponse),
      datetime: selectedLoan.response_datetime
        ? formatDateTime(selectedLoan.response_datetime, lang)
        : "-",
    },
    {
      ...(active.includes(selectedLoan.status)
        ? pendingReimbursement
        : selectedLoan.status === "closed" && selectedLoan.reimbursement_date
          ? reimbursementOK
          : defaultItem),
    },
  ];

  // Remove last item if reimbursement doesn't make sense
  if (selectedLoan.status === "refused" || selectedLoan.status === "pending") {
    items = items.slice(0, -1);
  }

  return (
    <LoanDetailsChapter
      title={t("financing:loans.details.statusChapter.title")}
      icon={<FactCheck />}
      className="StatusChapter"
    >
      <Timeline className="LoanTimeline">
        {items.map((item, index) => (
          <TimelineItem key={index} className={item.className}>
            <TimelineSeparator>
              <TimelineDot className={item.className}>{item.icon}</TimelineDot>
              {index < items.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <div className="Timeline-item-content">
                <p className="Timeline-item-content-text">{item.text}</p>
                <p className="Timeline-item-content-datetime">
                  {item.datetime}
                </p>
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </LoanDetailsChapter>
  );
}

function BankingChapter({ selectedLoan, onModalOpen }) {
  const { t } = useTranslation(["common", "financing"]);
  const dispatchModal = useModalDispatch();

  const style1 = {
    fontWeight: 600,
  };

  const iban = (iban) =>
    iban ? `IBAN: ${selectedLoan.bank_account.IBAN}` : "-";

  const today = new Date();

  const showPayment =
    selectedLoan.provider === "Defacto" &&
    selectedLoan.status !== "overdue" &&
    differenceInCalendarDays(selectedLoan.ending_date, today) > 3;

  const handlePayment = () => {
    onModalOpen();
    dispatchModal({
      type: "add",
      title: (
        <Trans
          t={t}
          i18nKey="financing:loans.details.bankingChapter.repay.modal.title"
          values={{ loanID: selectedLoan.ext_id }}
        />
      ),
      message: t("financing:loans.details.bankingChapter.repay.modal.message"),
      component: <PayDateModal loanID={selectedLoan.id} />,
      size: "medium",
    });
  };

  if (!selectedLoan.bank_account) return <></>;
  return (
    <LoanDetailsChapter
      title={t("financing:loans.details.bankingChapter.title")}
      icon={<FactCheck />}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <div
          style={{
            lineHeight: "1.5",
            fontSize: "smaller",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <div>
            <p style={style1}>
              {t("financing:loans.details.bankingChapter.accounts.receive")}
            </p>
            <p>{iban(selectedLoan.bank_account.IBAN)}</p>
          </div>

          <div>
            <p style={style1}>
              {t("financing:loans.details.bankingChapter.accounts.repay")}
            </p>
            <p>{iban(selectedLoan.bank_account.IBAN)}</p>
          </div>
        </div>

        {showPayment && (
          <Fragment>
            <div
              style={{
                lineHeight: "1.5",
                fontSize: "smaller",
              }}
            >
              <p style={{ color: "var(--color-leano-2)", fontWeight: "600" }}>
                {t("common:note")}
              </p>
              <p>{t("financing:loans.details.bankingChapter.repay.note")}</p>
            </div>

            <p style={{ fontWeight: "700", fontSize: "larger" }}>
              {selectedLoan.current_interest
                ? formatAmount(
                    selectedLoan.current_interest,
                    i18n.resolvedLanguage,
                    selectedLoan.currency,
                  )
                : "-"}
            </p>

            <Button
              onClick={handlePayment}
              style={{
                color: "var(--color-leano-2)",
                display: "inline-flex",
                fontSize: "medium",
                fontWeight: "600",
                marginBottom: "0.5rem",
              }}
              text={t("financing:loans.details.bankingChapter.repay.button")}
              icon={<ArrowForward />}
            />
          </Fragment>
        )}
      </div>
    </LoanDetailsChapter>
  );
}

function PayDateModal({ loanID }) {
  const dispatchModal = useModalDispatch();
  const dispatchToast = useToastsDispatch();
  const { t } = useTranslation(["common", "financing"]);
  const [date, setDate] = useState("");

  const handlePayment = async () => {
    const response = await api.anticipateReimbursement(loanID, {
      date: date,
    });
    let errors = [];
    switch (response.status) {
      case 200:
        dispatchModal({ type: "success" });
        dispatchToast({
          type: "add",
          variant: "success",
          heading: t(
            "financing:loans.details.bankingChapter.repay.modal.successToast.heading",
          ),
          subheading: t(
            "financing:loans.details.bankingChapter.repay.modal.successToast.subheading",
          ),
        });
        break;
      case 422:
        errors = await response.json();
        dispatchModal({ type: "success" });
        dispatchToast({
          type: "add",
          variant: "error",
          heading: t(
            "financing:loans.details.bankingChapter.repay.modal.errorToast.heading",
          ),
          subheading: (
            <ul>
              {errors.map((errorCode, index) => (
                <li key={index}>{errorCode}</li>
              ))}
            </ul>
          ),
        });
        break;
      default:
        dispatchModal({ type: "success" });
        dispatchToast({
          type: "add",
          variant: "error",
          heading: t(
            "financing:loans.details.bankingChapter.repay.modal.errorToast.heading",
          ),
          subheading: t(
            "financing:loans.details.bankingChapter.repay.modal.errorToast.subheading",
          ),
        });
        break;
    }
  };

  const dateInput = {
    name: "date",
    type: "date",
    label: t("financing:loans.details.bankingChapter.repay.modal.label"),
    value: date,
    onChange: setDate,
  };

  return <Form inputs={[{ data: [dateInput] }]} onSubmit={handlePayment} />;
}

function DocumentChapter({ selectedLoan }) {
  const { t } = useTranslation(["common", "financing"]);
  const style = {
    display: "flex",
    alignItems: "center",
    color: "var(--color-leano-1)",
    gap: "0.5rem",
    textDecoration: "none",
    fontWeight: "700",
    fontSize: "smaller",
  };

  let documents = [];

  if (selectedLoan.contract) {
    documents.push({
      id: "contract",
      label: t("common:contract"),
      value: selectedLoan.contract,
    });
  }

  if (selectedLoan.invoice) {
    documents.push({
      id: "invoice",
      label: t("common:invoice"),
      value: selectedLoan.invoice,
    });
  }

  if (documents.length === 0) return <></>;
  return (
    <LoanDetailsChapter
      title={t("financing:loans.details.documentChapter.title")}
      icon={<FactCheck />}
    >
      <ul
        style={{
          margin: "unset",
          lineHeight: "2",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        {documents.length > 0 &&
          documents.map((document) => (
            <li key={document.id}>
              <a style={style} href={document.value}>
                <OpenInBrowser fontSize="medium" />
                <span>{document.label}</span>
              </a>
            </li>
          ))}
      </ul>
    </LoanDetailsChapter>
  );
}

function ListFilters({ children }) {
  return (
    <div className="ListFilters" style={{ display: "flex", gap: "0.5rem" }}>
      {children}
    </div>
  );
}

function PartnerFilter({ availablePartners, onChange }) {
  const { t } = useTranslation(["common"]);

  const [value, setValue] = useState(null);
  const handleValueChange = (newValue) => {
    setValue(newValue);
    onChange(newValue ? newValue.value : () => true);
  };

  const options = availablePartners.map((partner) => ({
    label: partner,
    value: (loan) => loan.provider === partner,
  }));

  const input = {
    type: "select",
    placeholder: t("common:funder"),
    value: value,
    onChange: handleValueChange,
    isClearable: true,
    options: options,
  };
  return <Input className={"ListFilter"} {...input} />;
}

function CreationDateTimeFilter({ tabLoanList, onChange }) {
  const { t } = useTranslation(["common"]);

  // Array of unique creation dates values from current subset of loans
  const sortByDescendingDate = (a, b) => (a < b ? 1 : -1);
  const rawDates = tabLoanList
    .map((loan) => new Date(loan.creation_datetime))
    .sort(sortByDescendingDate);
  const availableISODates = Array.from(
    new Set(rawDates.map((date) => formatISO(date))),
  );

  const [value, setValue] = useState(null);
  const handleValueChange = (newValue) => {
    setValue(newValue);
    onChange(newValue ? newValue.value : () => true);
  };

  const options = availableISODates.map((ISODate) => ({
    label: formatDate(ISODate, i18n.resolvedLanguage),
    value: (loan) =>
      differenceInCalendarDays(
        new Date(loan.creation_datetime),
        new Date(ISODate),
      ) >= 0,
  }));

  const input = {
    type: "select",
    placeholder: t("financing:loans.table.filters.dateStartingAt"),
    value: value,
    onChange: handleValueChange,
    isClearable: true,
    options: options,
  };
  return <Input className={"ListFilter"} {...input} />;
}

function CreationDateTimeFilter2({ onChange }) {
  const { t } = useTranslation(["common"]);

  const [value, setValue] = useState("");
  const handleValueChange = (newValue) => {
    setValue(newValue);

    const newFilter = (loan) =>
      differenceInCalendarDays(
        new Date(loan.creation_datetime),
        new Date(newValue),
      ) >= 0;
    onChange(newValue ? newFilter : () => true);
  };

  const input = {
    type: "date",
    placeholder: t("financing:loans.table.filters.dateStartingAt"),
    value: value,
    onChange: handleValueChange,
  };
  return <Input className={"ListFilter"} {...input} />;
}

function StatusFilter({ availableStatus, onChange }) {
  const { t } = useTranslation(["common", "financing"]);

  const [value, setValue] = useState(null);
  const handleValueChange = (newValue) => {
    setValue(newValue);
    onChange(newValue ? newValue.value : () => true);
  };

  const options = availableStatus.map((status) => ({
    label: t(`financing:loans.status.${status}`),
    value: (loan) => loan.status === status,
  }));

  const input = {
    type: "select",
    placeholder: t("common:status"),
    value: value,
    onChange: handleValueChange,
    isClearable: true,
    options: options,
  };
  return <Input className={"ListFilter"} {...input} />;
}

function UsecaseFilter({ availableUsecases, onChange }) {
  const { t } = useTranslation(["common", "financing"]);

  const [value, setValue] = useState(null);
  const handleValueChange = (newValue) => {
    setValue(newValue);
    onChange(newValue ? newValue.value : () => true);
  };

  const options = availableUsecases.map((usecase) => ({
    label: t(`financing:unlock.usecases.ids.${usecase}.title`),
    value: (loanRequest) => loanRequest.use_case === usecase,
  }));

  const input = {
    type: "select",
    placeholder: t("common:usecase"),
    value: value,
    onChange: handleValueChange,
    isClearable: true,
    options: options,
  };
  return <Input className={"ListFilter"} {...input} />;
}

function NeedFilter({ availableNeeds, onChange }) {
  const { t } = useTranslation(["common", "financing"]);

  const [value, setValue] = useState(null);
  const handleValueChange = (newValue) => {
    setValue(newValue);
    onChange(newValue ? newValue.value : () => true);
  };

  const options = availableNeeds.map((need) => ({
    label: t(`financing:assessment.results.keys.${need}.name`),
    value: (loanRequest) => loanRequest.need === need,
  }));

  const input = {
    type: "select",
    placeholder: t("common:need"),
    value: value,
    onChange: handleValueChange,
    isClearable: true,
    options: options,
  };
  return <Input className={"ListFilter"} {...input} />;
}

function StartingDateFilter({ tabLoanList, onChange }) {
  const { t } = useTranslation(["common", "financing"]);

  // Array of unique starting dates values from current subset of loan request
  const sortByDescendingDate = (a, b) => (a < b ? 1 : -1);
  const rawDates = tabLoanList
    .map((loanRequest) => new Date(loanRequest.starting_date))
    .sort(sortByDescendingDate);
  const availableISODates = Array.from(
    new Set(rawDates.map((date) => formatISO(date))),
  );

  const [value, setValue] = useState(null);
  const handleValueChange = (newValue) => {
    setValue(newValue);
    onChange(newValue ? newValue.value : () => true);
  };

  const options = availableISODates.map((ISODate) => ({
    label: formatDate(ISODate, i18n.resolvedLanguage),
    value: (loanRequest) =>
      differenceInCalendarDays(
        new Date(loanRequest.starting_date),
        new Date(ISODate),
      ) >= 0,
  }));

  const input = {
    type: "select",
    placeholder: t("financing:loans.table.filters.dateStartingAt"),
    value: value,
    onChange: handleValueChange,
    isClearable: true,
    options: options,
  };
  return <Input className={"ListFilter"} {...input} />;
}

function StartingDateFilter2({ onChange }) {
  const { t } = useTranslation(["common", "financing"]);

  const [value, setValue] = useState("");
  const handleValueChange = (newValue) => {
    setValue(newValue);

    const newFilter = (loanRequest) =>
      differenceInCalendarDays(
        new Date(loanRequest.starting_date),
        new Date(newValue),
      ) >= 0;

    onChange(newValue ? newFilter : () => true);
  };

  const input = {
    type: "date",
    placeholder: t("financing:loans.table.filters.dateStartingAt"),
    value: value,
    onChange: handleValueChange,
  };
  return <Input className={"ListFilter"} {...input} />;
}

function LoanTabs({ tabs, selectedTab, onSelect }) {
  const { t } = useTranslation(["common", "financing"]);
  return (
    <Tabs
      smallTabs
      tabs={tabs.map((tab) => ({
        id: tab.id,
        title: tab.title ? tab.title : t(`financing:loans.tabs.${tab.id}`),
      }))}
      selectedID={selectedTab}
      onSelect={onSelect}
    />
  );
}

function LoanCounters({ loanCounters }) {
  const { t } = useTranslation(["common", "financing"]);

  const counters = [
    {
      id: "pending",
      count: loanCounters.pending,
      backgroundColor: "var(--color-warning-3)",
      text: t("financing:loans.counterCards.pending", { returnObjects: true }),
    },
    {
      id: "accepted",
      count: loanCounters.accepted,
      backgroundColor: "var(--color-success-3)",
      text: t("financing:loans.counterCards.ongoing", { returnObjects: true }),
      timeframe: t("financing:loans.counterCards.timeframe"),
    },
  ];

  return (
    <div className="LoanCounters">
      {counters.map((counter) => (
        <div
          key={counter.id}
          className="LoanCounter"
          style={{ backgroundColor: counter.backgroundColor }}
        >
          <span className="LoanCounter-count">{counter.count}</span>
          <div className="LoanCounter-text">
            {counter.text.map((line, index) => (
              <span key={index}>{line}</span>
            ))}
          </div>
          <span className="LoanCounter-timeframe">{counter.timeframe}</span>
        </div>
      ))}
    </div>
  );
}
